<template>

  <div class="mg">
      
        <img src="@/assets/images/pinpai1.jpg" alt="">
        <img src="@/assets/images/pinpai2.jpg" alt="">
      
  </div>

</template>

<script>
export default {
    name: 'DevelopmentHistory'
}
</script>

<style scoped>
.mg>img{
  width: 100%;
}
</style>